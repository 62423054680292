<template>
  <div class="full-page no-campaign">
    <h1>ไม่พบ Campaign</h1>
    <p>
      ขออภัยไม่มี Campaign ที่คุณกำลังหา
    </p>
  </div>
</template>

<script>
export default {
  name: 'NoCampaign',
  data() {
    return {
      // slug: localStorage.getItem('slug') || null,
      slug: 'ps5',
      error: this.$route.params.error,
    }
  },
  mounted() {
    if (this.slug && this.error !== 'expired') {
      // this.$router.push({ name: 'PS5' })
    }
  },
}
</script>

<style lang="stylus" scoped>
.no-campaign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

h1 {
  font-size: 36px;
  color: #333;
}

p {
  font-size: 18px;
  color: #666;
}
</style>
